/* eslint-disable */
import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FormControlComponent } from '../form-control/form-control.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormControlComponent],
})
export class InputComponent {
  @Input() title: string = '';
  @Input({ transform: booleanAttribute }) disabled: boolean = false;
  @Input() control: UntypedFormControl | any = new UntypedFormControl();
  @Input({ transform: booleanAttribute }) readonly: boolean = false;
  @Input() type: string = 'text';
  @Input() placeholder: string = '';

  @Output() changeEvent = new EventEmitter();

  onChange() {
    this.changeEvent.emit();
  }
}
