import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { InputComponent } from './inputs/input/input.component';
import { MessageService } from './core/services/message.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [InputComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  showModal: boolean = false;
  modalHeight: number = 0;
  formGroup: FormGroup;
  sent: boolean = false;

  public CLUE: string = 'GAME_Vigenère';

  private readonly messageService = inject(MessageService);

  constructor(private fb: FormBuilder) {
    this.formGroup = fb.group({
      message: fb.control('', [Validators.required]),
    });
  }

  control(name: string) {
    return this.formGroup.get(name) as FormControl;
  }

  toggleModal() {
    this.showModal = !this.showModal;
    this.modalHeight = window.innerHeight;
  }

  send() {
    if (this.formGroup.valid && !this.sent) {
      this.sent = true;
      this.messageService.send(this.formGroup.getRawValue()).subscribe();
    }
  }
}
