<div class="app">
  <div class="container">
    <a
      target="_blank"
      href="https://t.me/+UvbtKljRUuU5OTQy"
      class="button"
      rel="noreferrer noopener"
    >
      <img class="button_icon" src="/assets/img/icons/tg.webp" alt="telegram" />
      <span class="button_text">Telegram</span>
    </a>
    <a
      target="_blank"
      href="https://twitter.com/TapFunWin"
      class="button"
      rel="noreferrer noopener"
    >
      <img class="button_icon" src="/assets/img/icons/tw.webp" alt="twitter" />
      <span class="button_text">Twitter</span>
    </a>
    <button type="button" class="button button_red" (click)="toggleModal()">
      <img class="lock-icon" src="/assets/img/icons/lock.webp" alt="lock" />
      <span class="button_text"> START PLAYING</span>
    </button>
  </div>
</div>

@if (showModal) {
<div class="modal">
  <div
    class="modal_content"
    [style.height]="
      modalHeight >= 800 ? 'fit-content' : modalHeight - 100 + 'px'
    "
  >
    <img
      class="modal_close"
      src="/assets/img/icons/close.webp"
      (click)="toggleModal()"
    />
    <div class="modal_text">
      Ekrey lxma vyx xeu trl oqhlxv, wex wmcl xpy nyxp xaso fbn, zsi srsg zlkn
      tycnw. Va jcqvzbvl vlx rpbxiu tw cgbnm: kgclrc zlbeoqa, qet jkfetgyt,
      bcbwdclhxy ju tqqbdsg, puy fqa ollo jfvmzkoc lwhrld, pfrs lclvclxm, fu
      lecfyfytk fblx mwll jvfwy zte hrptal fy vzx svaw
      <br />
      Gzoct pkaupk dn gzkat tghww
      <br />
      Gz vte eip ql vte Gozr vzw tizq rzdtvkn ivvkrmnhjx hrzy, vzw tizq fpnd dzr
      tnk pa pkoikd vte pkujtsh gnd wkc yohbvv akwtlhoyut
      <br /><br />
      T1-4:<br />
      G minor: Bb D F<br />
      D minor: F A C<br />
      Eb major: G Bb D<br />
      Bb major: D F A<br />
      D major: F# A C#<br />
      G minor: Bb D F<br />
      <br /><br />
      T5-8:<br />
      G minor: Bb D F<br />
      C minor: Eb G Bb<br />
      G minor/Bb: D F Bb<br />
      A diminished: C Eb G<br />
      D7: F# A C<br />
      G minor: Bb D F
    </div>
    <div class="from">
      @if (!sent) {
      <app-input
        [control]="control('message')"
        [title]="'KEY'"
        [type]="'text'"
      ></app-input>
      } @else {
      <span class="from_msg">Thank you for your answer!</span>
      }
    </div>
    <button type="button" class="button button_red" (click)="send()">
      <span class="button_text">Send</span>
    </button>
  </div>
</div>
}
