import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrl: './form-control.component.scss',
  standalone: true,
  imports: [NgClass],
})
export class FormControlComponent {
  @Input() withError: boolean = false;
  @Input() title: string = '';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder: string = '';
  @Input() showErrors: boolean = true;
}
