<app-form-control
  [control]="control"
  [title]="title"
  [placeholder]="placeholder"
>
  <input
    class="control-inpt"
    [formControl]="control"
    autocomplete="off"
    [class.disabled]="disabled"
    [readonly]="readonly"
    [type]="type"
    [attr.disabled]="disabled"
    [readOnly]="disabled"
    (change)="onChange()"
  />
</app-form-control>
