<div class="form-inpt">
  <div class="form-inpt__header">
    @if (title?.length) {
    <div class="form-inpt__title" [innerHTML]="title"></div>
    }
  </div>
  <div class="form-inpt__wrapper">
    <ng-content></ng-content>
    @if (!control?.value) {
    <span class="placeholder" [innerHTML]="placeholder"></span>
    }
  </div>
</div>
